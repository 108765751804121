const __AUTH_TYPES__ = [
  {
    id: "normal",
    name: "Normal Otorizasyon",
  },
  {
    id: "pre",
    name: "Ön Otorizasyon",
  },
];

const secureMethods = [
  {
    name: "storeKey",
    label: "3D Secure Anahtarı",
    form: "input",
    type: "text",
    value: null,
    hint:
      "Banka (debit) kartlarından ödeme alabilmek için bu bilgi gereklidir.",
  },
  {
    name: "secure",
    label: "3D Secure",
    form: "select",
    route: "all/payment-secure-options",
    options: [],
    value: null,
    required: true,
  },
  {
    name: "secureMethod",
    label: "3D Yöntemi",
    form: "select",
    route: "all/payment-secure-methods",
    options: [],
    value: null,
  },
  {
    name: "authType",
    label: "Otorizasyon Tipi",
    form: "select",
    options: __AUTH_TYPES__,
    value: null,
  },
];

const authCredential = [
  {
    name: "username",
    label: "Kullanıcı Adı",
    form: "input",
    type: "text",
    value: null,
    required: true,
  },
  {
    name: "password",
    label: "Parola",
    form: "input",
    type: "text",
    value: null,
    required: true,
  },
];

const nestPayElements = [
  {
    name: "clientId",
    label: "Mağaza Numarası",
    form: "input",
    type: "text",
    value: null,
    required: true,
  },
  ...authCredential,
  ...secureMethods,
  {
    name: "bolum_pesin",
    label: "Bölüm (Tek Çekim)",
    form: "input",
    type: "text",
    value: null,
  },
  {
    name: "bolum_taksit",
    label: "Bölüm (Taksit)",
    form: "input",
    type: "text",
    value: null,
  },
];

const defaultFormData = {
  is_active: 0,
  is_default: 0,
  is_test: 0,
  use_installment: false,
  min_installment_amount: 0,
  rates: [],
  installment: {
    price: 0,
    start: null,
    end: null,
    extra: null,
  },
};

const nestPayData = {
  ...defaultFormData,
  elements: [...nestPayElements],
};

export default {
  items: [
    {
      name: "ziraat",
      bank: "Ziraat Bankası",
      credit_card: "Bankkart Combo",
      auth_type: null,
      ...nestPayData,
    },
    {
      name: "ak",
      bank: "AkBank",
      credit_card: "",
      auth_type: null,
      ...nestPayData,
    },
    {
      name: "qnb",
      bank: "QNB Finansbank A.Ş.",
      credit_card: "",
      auth_type: null,
      ...nestPayData,
    },
    {
      name: "kuveyt",
      bank: "Kuveyt Türk Katılım Bankası A.Ş.",
      credit_card: "Kuveyt Türk Kredi Kartı",
      auth_type: null,
      elements: [
        {
          name: "customerId",
          label: "Müşteri Numarası",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "merchantId",
          label: "Mağaza Numarası",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        ...authCredential,
        ...secureMethods,
      ],
    },
    {
      name: "garanti",
      bank: "Garanti BBVA",
      credit_card: "Bonus Card",
      auth_type: null,
      elements: [
        {
          name: "customerId",
          label: "Müşteri Numarası",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "merchantId",
          label: "Mağaza Numarası",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "terminalId",
          label: "Terminal Numarası",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        ...authCredential,
        ...secureMethods,
      ],
    },
    {
      name: "anadolu",
      bank: "ANADOLUBANK A.Ş.",
      credit_card: "",
      auth_type: null,
      ...nestPayData,
    },
    {
      name: "ing",
      bank: "ING Bank A.Ş.",
      credit_card: "",
      auth_type: null,
      ...nestPayData,
    },
    {
      name: "halk",
      bank: "Türkiye Halk Bankası A.Ş.",
      credit_card: "",
      auth_type: null,
      ...nestPayData,
    },
    {
      name: "isbank",
      bank: "Türkiye İş Bankası",
      credit_card: "Maximum Kart",
      auth_type: null,
      ...nestPayData,
    },
    {
      name: "yapikredi",
      bank: "Yapıkredi Bankası",
      credit_card: "WorldCard",
      ...defaultFormData,
      auth_type: null,
      elements: [
        {
          name: "merchantId",
          label: "Merchant ID",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "terminalId",
          label: "Terminal ID",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "posnetId",
          label: "Posnet ID",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "enckKey",
          label: "ENC Key",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "secure",
          label: "3D Secure",
          form: "select",
          route: "all/payment-secure-options",
          options: [],
          value: null,
          required: true,
        },
        {
          name: "authType",
          label: "Otorizasyon Tipi",
          form: "select",
          options: __AUTH_TYPES__,
          value: null,
        },
      ],
    },
    {
      name: "transilvania",
      bank: "Banca Transilvania",
      credit_card: "BTePOS",
      ...defaultFormData,
      auth_type: null,
      elements: [
        {
          name: "userName",
          label: "Kullanıcı Adı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "Şifre",
          form: "input",
          type: "password",
          value: null,
          required: true,
        },
      ],
    }
  ],
};
